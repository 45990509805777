import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    IconButton,
    Button,
    List,
    ListItem,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Chip,
    InputAdornment,
    Avatar,
    Fab
} from '@mui/material';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import Linkify from 'react-linkify';
import { ANS } from "@alph-name-service/ans-sdk";
import { TransactionBuilder } from '@alephium/web3';
import { useWallet } from '@alephium/web3-react';
import ForeignPerfil from './ForeignPerfil'; // Import the ForeignPerfil component

const PostCard = ({
    post,
    currentPostId,
    setCurrentPostId,
    handleLikePost,
    handleCreateComment,
    commentContent,
    setCommentContent
}) => {
    const { account } = useWallet();
    const [ansName, setAnsName] = useState(null);
    const [ansUri, setAnsUri] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isTipDialogOpen, setIsTipDialogOpen] = useState(false);
    const [tipAmount, setTipAmount] = useState('');
    const [commentAnsDetails, setCommentAnsDetails] = useState({});
    const [isProfileDialogOpen, setIsProfileDialogOpen] = useState(false); // State to control the profile dialog

    useEffect(() => {
        const fetchAnsName = async () => {
            try {
                const ans = new ANS("testnet");
                const profile = await ans.getProfile(post.author);
                if (profile?.name) {
                    setAnsName(profile.name);
                }
                if (profile?.imgUri) {
                    setAnsUri(profile.imgUri);
                }
            } catch (error) {
                // Handle error if needed
            }
        };
        fetchAnsName();
    }, [post.author]);

    useEffect(() => {
        const fetchCommentAnsDetails = async () => {
            try {
                const ans = new ANS("testnet");
                const details = {};
                for (const comment of post.comments) {
                    const profile = await ans.getProfile(comment.user);
                    details[comment.user] = {
                        name: profile?.name || null,
                        imgUri: profile?.imgUri || null
                    };
                }
                setCommentAnsDetails(details);
            } catch (error) {
                console.error("Error fetching ANS for comments:", error);
            }
        };
        fetchCommentAnsDetails();
    }, [post.comments]);

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setCurrentPostId(null);
    };

    const handleCommentIconClick = () => {
        setCurrentPostId(post.id);
        handleOpenDialog();
    };

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    const handleTipClick = () => {
        setIsTipDialogOpen(true);
    };

    const handleCloseTipDialog = () => {
        setIsTipDialogOpen(false);
        setTipAmount('');
    };

    const handleSendTip = async () => {
        const nodeUrl = process.env.NODE_URL;
        const senderAddress = account.address;
        const senderPublicKey = account.publicKey;
        const receiverAddress = post.author;

        const builder = TransactionBuilder.from(nodeUrl);
        const buildTxResult = await builder.buildTransferTx(
            {
                signerAddress: senderAddress,
                destinations: [{
                    address: receiverAddress,
                    attoAlphAmount: tipAmount
                }]
            },
            senderPublicKey
        );

        console.log('unsigned transaction', buildTxResult.unsignedTx);

        handleCloseTipDialog();
    };

    const highlightAddress = (address) => {
        if (!address) return '';
        const start = `${address.slice(0, 5)}`;
        const end = `${address.slice(-4)}`;
        return (
            <span>
                <a style={{ color: "#007bff" }}>{start}</a>
                <a style={{ color: "white" }}>...{end}</a>
            </span>
        );
    };

    const handleOpenProfileDialog = () => {
        setIsProfileDialogOpen(true);
    };

    const handleCloseProfileDialog = () => {
        setIsProfileDialogOpen(false);
    };

    // Link decorator to style the links
    const linkDecorator = (href, text, key) => (
        <a
            href={href}
            key={key}
            style={{ color: '#1e88e5', textDecoration: 'underline' }}
            target="_blank"
            rel="noopener noreferrer"
        >
            {text}
        </a>
    );

    return (
        <Card sx={{ marginTop: 2, backgroundColor: 'rgba(255, 255, 255, 0.0)', boxShadow: 10, borderRadius: 2, color: 'white' }}>
            <CardContent>
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                    <Linkify componentDecorator={linkDecorator}>
                        {isExpanded ? post.content : `${post.content.slice(0, 100)}${post.content.length > 100 ? '...' : ''}`}
                    </Linkify>
                </Typography>
                {post.content.length > 100 && (
                    <Button onClick={handleExpandClick} sx={{ color: 'white', textTransform: 'none' }}>
                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        {isExpanded ? 'Show Less' : 'Read More'}
                    </Button>
                )}
                <Button onClick={handleOpenProfileDialog}>  {/* Open the ForeignPerfil dialog */}
                    <Chip
                        label={
                            <Typography variant="subtitle2" component="div">
                                {ansName || highlightAddress(post.author)}
                            </Typography>
                        }
                        sx={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                    />
                </Button>
            </CardContent>
            <CardActions>
                <IconButton size="small" color="primary" onClick={() => handleLikePost(post.id)}>
                    <HandshakeIcon />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>{post.likes.length}</Typography>
                </IconButton>
                <IconButton size="small" color="primary" onClick={handleCommentIconClick}>
                    <CommentIcon />
                    <Typography variant="body2" sx={{ marginLeft: 1 }}>{post.comments.length}</Typography>
                </IconButton>
                <Button  disabled size="small" color="primary" onClick={handleTipClick}>
                    <AttachMoneyIcon /> Tip
                </Button>
            </CardActions>

            {/* ForeignPerfil Dialog */}
            <Dialog
                open={isProfileDialogOpen}
                onClose={handleCloseProfileDialog}
                fullScreen
                PaperProps={{
                    sx: {
                        margin: 0,
                        padding: 0,
                        backgroundColor: 'transparent',
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.94)',
                    }
                }}
            >
                <ForeignPerfil address={post.author} />
                <Fab
                    color="secondary"
                    aria-label="close"
                    onClick={handleCloseProfileDialog}
                    sx={{ position: 'fixed', top: 16, right: 16 }}
                >
                    <CloseIcon />
                </Fab>
            </Dialog>

            <Dialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                PaperProps={{
                    sx: {
                        backgroundColor: "rgba(255,255,255,0)",
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        scrollbarWidth: 'none',
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.94)',
                    }
                }}
                fullWidth
                maxWidth="sm"
            >
                <DialogContent>
                    <TextField
                        label="Write a comment"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        value={commentContent}
                        onChange={(e) => setCommentContent(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start"></InputAdornment>
                            ),
                        }}
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0)',
                            borderRadius: 1,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'rgba(255,255,255,0.1)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(255,255,255,0.3)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'rgba(255,255,255,0.2)',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: 'white',
                            },
                            '& .MuiInputLabel-root': {
                                color: 'rgba(255, 255, 255, 0.7)',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: 'white',
                            }
                        }}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { handleCreateComment(post.id); }}
                        startIcon={<SendIcon />}
                        sx={{ marginTop: 2, width: '100%' }}
                    >
                        Submit Comment
                    </Button>
                    <List
                        sx={{
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            maxHeight: '50vh',
                            overflowY: 'auto',
                            scrollbarWidth: 'none',
                        }}
                    >
                        {post.comments.map((comment) => (
                            <ListItem key={comment.id} sx={{ borderRadius: 1, marginBottom: 1, color: 'white' }}>
                                <ListItemText
                                    primary={comment.content}
                                    secondary={
                                        <>
                                            {commentAnsDetails[comment.user]?.name ? (
                                                <Typography variant="subtitle2" component="div" sx={{ color: "#007bff" }}>
                                                    {commentAnsDetails[comment.user].name}
                                                </Typography>
                                            ) : (
                                                highlightAddress(comment.user)
                                            )}
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isTipDialogOpen}
                onClose={handleCloseTipDialog}
                PaperProps={{
                    sx: {
                        backgroundColor: "rgba(255,255,255,0)",
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        scrollbarWidth: 'none',
                    },
                }}
                BackdropProps={{
                    style: {
                        backgroundColor: 'rgba(0, 0, 0, 0.94)',
                    }
                }}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Send Tip</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Amount"
                        variant="outlined"
                        fullWidth
                        value={tipAmount}
                        onChange={(e) => setTipAmount(e.target.value)}
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0)',
                            borderRadius: 1,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'rgba(255,255,255,0.1)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(255,255,255,0.3)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'rgba(255,255,255,0.2)',
                                },
                            },
                            '& .MuiInputBase-input': {
                                color: 'white',
                            },
                            '& .MuiInputLabel-root': {
                                color: 'rgba(255, 255, 255, 0.7)',
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: 'white',
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseTipDialog} color="primary">Cancel</Button>
                    <Button onClick={handleSendTip} color="primary">Send Tip</Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

export default PostCard;
