import React, { useEffect, useState, useCallback } from 'react';
import {
  Typography,
  Box,
  Avatar,
  CardMedia,
  Grid,
  Container,
  CircularProgress,
  LinearProgress,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import moment from 'moment';
import LaunchCard from './LaunchCard'; // Assuming LaunchCard is in the same directory
import './customStyles.css'; // Assuming you have this CSS file for custom styles

const Launchpad = () => {
  const [launches, setLaunches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchLaunches = async () => {
      try {
        const response = await fetch('https://worker.alphpad.com/api/sale');
        const data = await response.json();
        setLaunches(data.data);
      } catch (error) {
        console.error('Error fetching launches:', error);
        setError('Failed to fetch launches. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchLaunches();
  }, []);

  const getStatus = useCallback((startDate, endDate) => {
    const now = moment();
    const start = moment(parseInt(startDate, 10));
    const end = moment(parseInt(endDate, 10));

    if (now.isBefore(start)) {
      return `Starts in ${start.diff(now, 'days')} days`;
    } else if (now.isAfter(end)) {
      return 'Ended';
    } else {
      return 'Ongoing';
    }
  }, []);

  const formatAmount = useCallback((amount, decimals = 18) => {
    const adjustedAmount = amount / Math.pow(10, decimals);
    if (adjustedAmount >= 1e6) {
      return `${(adjustedAmount / 1e6).toFixed(2)}M`;
    } else if (adjustedAmount >= 1e3) {
      return `${(adjustedAmount / 1e3).toFixed(2)}k`;
    } else {
      return adjustedAmount.toLocaleString();
    }
  }, []);

  const displayValue = useCallback((value, decimals) => {
    try {
      return formatAmount(parseInt(value, 10), decimals);
    } catch (error) {
      return value;
    }
  }, [formatAmount]);

  return (
    <Container sx={{ width: '100%', padding: 10, minHeight: '100vh', overflowY: 'auto' }}>
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 4, fontWeight: 'bold', color: '#fff' }}>
        ALPHPAD Launchpad
      </Typography>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={4}>
          {launches.map((launch) => {
            const logoUrl = `https://listing.alphpad.com/listings/${launch.directory}/${launch.logo}`;
            const bannerUrl = `https://listing.alphpad.com/listings/${launch.directory}/${launch.banner}`;
            const status = getStatus(launch.state.saleStart, launch.state.saleEnd);
            const decimals = 18;
            const tokenPrice = displayValue(launch.state.tokenPrice, decimals);
            const totalRaised = displayValue(launch.state.totalRaised, decimals);
            const minRaise = displayValue(launch.state.minRaise, decimals);
            const maxRaise = displayValue(launch.state.maxRaise, decimals);
            const raisedPercent = ((launch.state.totalRaised - launch.state.minRaise) / (launch.state.maxRaise - launch.state.minRaise)) * 100;

            return (
              <Grid item xs={12} md={6} lg={4} key={launch.contractId}>
                {isMobile ? (
                  <LaunchCard launch={launch} />
                ) : (
                  <Card sx={{ borderRadius: 4, boxShadow: 3, overflow: 'hidden', width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <CardMedia
                      component="img"
                      height={150}
                      image={bannerUrl}
                      alt={launch.name}
                      sx={{ objectFit: 'cover', filter: 'brightness(0.8)' }}
                    />
                    <CardContent sx={{ flexGrow: 1, position: 'relative', paddingBottom: '16px !important' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Avatar
                          src={logoUrl}
                          alt={launch.name}
                          sx={{
                            width: 80,
                            height: 80,
                            border: '3px solid white',
                            boxShadow: 2,
                            marginRight: 2,
                          }}
                        />
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '70%' }}>
                          {launch.name}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle1" sx={{ color: '#666', marginBottom: 1 }}>
                        {launch.token}
                      </Typography>
                      <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#999', marginBottom: 2 }}>
                        {launch.shortDesc}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ color: status === 'Ended' ? 'red' : status === 'Ongoing' ? 'green' : 'orange', marginBottom: 2 }}
                      >
                        {status}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 2 }}>
                        <Typography variant="body2">{minRaise}</Typography>
                        <LinearProgress variant="determinate" value={raisedPercent} sx={{ flex: 1, backgroundColor: '#e0e0e0' }} />
                        <Typography variant="body2">{maxRaise}</Typography>
                      </Box>
                      <Typography variant="body2" sx={{ color: '#333', marginBottom: 1 }}>
                        Raised: {totalRaised} $ALPH
                      </Typography>
                      <Typography variant="body2" sx={{ color: '#333', marginBottom: 2 }}>
                        Price: {tokenPrice}
                      </Typography>
                      <Button variant="contained" color="primary" href={`https://app.alphpad.com/launchpad/${launch.directory}`} target="_blank" fullWidth>
                       more info
                      </Button>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            );
          })}
        </Grid>
      )}
    </Container>
  );
};

export default Launchpad;
