import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, TextField, List, ListItem, Box, Avatar, Checkbox, FormControlLabel, Grid, Chip } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

const Pulse = () => {
    const [newsLinks, setNewsLinks] = useState([]);
    const [newLink, setNewLink] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [description, setDescription] = useState('');
    const [cashtags, setCashtags] = useState('');
    const [telegramChecked, setTelegramChecked] = useState(false);
    const [twitterChecked, setTwitterChecked] = useState(false);

    const handlePostLink = () => {
        const newNewsLink = {
            id: newsLinks.length + 1,
            url: newLink,
            img: imgUrl,
            description: description,
            cashtags: cashtags.split(',').map(tag => tag.trim()),
            platforms: {
                telegram: telegramChecked,
                twitter: twitterChecked
            }
        };
        setNewsLinks([newNewsLink, ...newsLinks]);
        setNewLink('');
        setImgUrl('');
        setDescription('');
        setCashtags('');
        setTelegramChecked(false);
        setTwitterChecked(false);
    };

    return (
        <Box sx={{ padding: 2 }}>
            <Card sx={{ marginBottom: 2, padding: 2, backgroundColor: 'rgba(255, 255, 255, 0.0)', boxShadow: 10, borderRadius: 2 }}>
                <CardContent>
                    <Typography variant="h5" sx={{ marginBottom: 2, color: 'white' }}> Pulse</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={newLink}
                        onChange={(e) => setNewLink(e.target.value)}
                        placeholder="Enter a news link to share..."
                        sx={textFieldStyles}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={imgUrl}
                        onChange={(e) => setImgUrl(e.target.value)}
                        placeholder="Enter an image URL..."
                        sx={{ ...textFieldStyles, marginTop: 2 }}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={2}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Enter a short description..."
                        sx={{ ...textFieldStyles, marginTop: 2 }}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={cashtags}
                        onChange={(e) => setCashtags(e.target.value)}
                        placeholder="Enter cashtags (comma separated)..."
                        sx={{ ...textFieldStyles, marginTop: 2 }}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={telegramChecked} onChange={(e) => setTelegramChecked(e.target.checked)} />}
                        label="Share on Telegram"
                        sx={{ color: 'white', marginTop: 2 }}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={twitterChecked} onChange={(e) => setTwitterChecked(e.target.checked)} />}
                        label="Share on Twitter"
                        sx={{ color: 'white' }}
                    />
                    <Button variant="contained" color="primary" onClick={handlePostLink} sx={{ marginTop: 2 }}>Post</Button>
                </CardContent>
            </Card>
            <List>
                {newsLinks.map(news => (
                    <ListItem key={news.id} sx={{ padding: 0, marginBottom: 2 }}>
                        <Card sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, backgroundColor: 'rgba(255, 255, 255, 0.0)', boxShadow: 10, borderRadius: 2, color: 'white', width: '100%' }}>
                            <Avatar variant="square" src={news.img} sx={{ width: { xs: '100%', sm: 150 }, height: 150 }} />
                            <CardContent sx={{ flex: 1, padding: 2 }}>
                                <Typography variant="h6" sx={{ color: 'white', wordBreak: 'break-word' }}>{news.description}</Typography>
                                <Typography variant="body2" sx={{ color: 'gray', marginTop: 1 }}>{news.url}</Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', marginTop: 1 }}>
                                    {news.cashtags.map(tag => (
                                        <Chip key={tag} label={tag} sx={{ marginRight: 1, marginBottom: 1, color: 'white', backgroundColor: '#007bff' }} />
                                    ))}
                                </Box>
                                <Box sx={{ display: 'flex', marginTop: 1 }}>
                                    {news.platforms.telegram && (
                                        <Typography variant="body2" sx={{ color: 'white', marginRight: 2 }}>Telegram</Typography>
                                    )}
                                    {news.platforms.twitter && (
                                        <Typography variant="body2" sx={{ color: 'white' }}>Twitter</Typography>
                                    )}
                                </Box>
                            </CardContent>
                        </Card>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgba(255,255,255,0.1)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(255,255,255,0.3)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(255,255,255,0.2)',
        },
    },
    '& .MuiInputBase-input': {
        color: 'white',
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(255, 255, 255, 0.7)',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: 'white',
    }
};

export default Pulse;
