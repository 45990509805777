import React, { useState, useCallback, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_POSTS } from '../graphql/query/posts';
import { LIKE_POST, CREATE_COMMENT } from '../graphql/mutations/posts';
import {
    Box, Typography, CircularProgress, Grid, IconButton, TextField, InputAdornment
} from '@mui/material';
import { Sort } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { NetworkStatus } from '@apollo/client';
import PostCard from './PostCard';
import { styled } from '@mui/system';

const PostsWrapper = styled(Box)(({ theme }) => ({
    width: '100%',
    maxWidth: '600px',
    maxHeight: '50vh',
    overflowY: 'auto',
    paddingRight: theme.spacing(1),
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    scrollbarWidth: 'none',
    marginBottom: "10vh"
}));

const customTextFieldStyles = {
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgba(255,255,255,0.1)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(255,255,255,0.3)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'rgba(255,255,255,0.2)',
        },
    },
    '& .MuiInputBase-input': {
        color: 'white',
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(255, 255, 255, 0.7)',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: 'white',
    },
};

const Posts = ({ address }) => {
    const [order, setOrder] = useState('DESC');
    const [searchTerm, setSearchTerm] = useState('');
    const [offset, setOffset] = useState(0);
    const limit = 10;

    const [getPosts, { data, loading, error, fetchMore, networkStatus, refetch }] = useLazyQuery(GET_POSTS, {
        variables: { order, author: address, search: searchTerm, offset, limit },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
    });

    const [likePost] = useMutation(LIKE_POST);
    const [createComment] = useMutation(CREATE_COMMENT);

    const [commentContent, setCommentContent] = useState('');
    const [currentPostId, setCurrentPostId] = useState('');
    const [expandedPosts, setExpandedPosts] = useState({});

    useEffect(() => {
        if (address) {
            getPosts();
        }
    }, [address, getPosts]);

    const toggleOrder = () => {
        const newOrder = order === 'DESC' ? 'ASC' : 'DESC';
        setOrder(newOrder);
        setOffset(0);
        getPosts({ variables: { order: newOrder, author: address, search: searchTerm, offset: 0, limit } });
    };

    const handleLikePost = async (postId) => {
        try {
            await likePost({
                variables: {
                    likePostInput: {
                        postId,
                        user: address,
                    },
                },
                refetchQueries: [{ query: GET_POSTS, variables: { order, author: address, search: searchTerm, offset: 0, limit } }],
            });
        } catch (error) {
            console.error('Error liking post:', error);
        }
    };

    const handleCreateComment = async (postId) => {
        try {
            await createComment({
                variables: {
                    createCommentInput: {
                        postId,
                        content: commentContent,
                        user: address,
                    },
                },
                refetchQueries: [{ query: GET_POSTS, variables: { order, author: address, search: searchTerm, offset: 0, limit } }],
            });
            setCommentContent(''); // Clear the comment input
            setCurrentPostId(''); // Reset the current post
        } catch (error) {
            console.error('Error creating comment:', error);
        }
    };

    const handleLoadMore = () => {
        fetchMore({
            variables: {
                offset: data?.posts.length,
                limit,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                    ...prev,
                    posts: [...prev?.posts, ...fetchMoreResult?.posts],
                };
            },
        });
    };

    const handleScroll = useCallback((e) => {
        if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
            handleLoadMore();
        }
    }, [handleLoadMore]);

    if (error) return <div>Error! {error.message}</div>;

    return (
        <>
            <TextField
                label="Search Posts"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton>
                                <SearchIcon style={{ color: 'white' }} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                sx={{ marginTop: 5, marginBottom: 2, ...customTextFieldStyles }}
            />
            <Grid container width="100%" direction="row" alignItems="center" justifyContent="flex-start">
                <Grid item>
                    <IconButton onClick={toggleOrder}>
                        <Sort />
                    </IconButton>
                </Grid>
            </Grid>
            <PostsWrapper onScroll={handleScroll}>
                {loading && networkStatus !== NetworkStatus.fetchMore && (
                    <Box display="flex" justifyContent="center" mt={2}>
                        <CircularProgress />
                    </Box>
                )}
                {!loading && data?.posts?.length === 0 && (
                    <Typography>Nothing yet 😊</Typography>
                )}
                {data?.posts?.length > 0 && (
                    data.posts.map((post) => (
                        <PostCard
                            key={post.id}
                            post={post}
                            currentPostId={currentPostId}
                            expandedPosts={expandedPosts}
                            setCurrentPostId={setCurrentPostId}
                            setExpandedPosts={setExpandedPosts}
                            handleLikePost={handleLikePost}
                            handleCreateComment={handleCreateComment}
                            commentContent={commentContent}
                            setCommentContent={setCommentContent}
                        />
                    ))
                )}
            </PostsWrapper>
        </>
    );
};




export default Posts;
