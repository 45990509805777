import { gql, useQuery } from "@apollo/client";

export const Me = gql`
	query Me {
		me {
			_id
			address
			isFirstLogin
			url
			appPreferences {
        title
        headerImg
        headerImgSrc
        headerImgColor
        backgroundImg
        backgroundImgSrc
        backgroundImgRepeat
        backgroundImgColor
}


		}
	}
`;
