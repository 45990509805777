import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Avatar,
  Box,
  Chip,
  Backdrop,
  Fade,
} from '@mui/material';
import moment from 'moment';
import { keyframes } from '@mui/system';

const isLaunchActive = (startDate, endDate) => {
  const now = moment();
  const start = moment(parseInt(startDate, 10));
  const end = moment(parseInt(endDate, 10));

  if (now.isBefore(start)) {
    return `Starts in ${start.diff(now, 'days')} days`;
  } else if (now.isAfter(end)) {
    return 'Ended';
  } else {
    return 'Ongoing';
  }
};

const zoomIn = keyframes`
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1.2);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const LaunchCard = ({ launch }) => {
  const [showEmojis, setShowEmojis] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);
  const [message, setMessage] = useState('');
  const [fireCount, setFireCount] = useState(42); // Stub count for "fire" emoji
  const [sadCount, setSadCount] = useState(17); // Stub count for "sad" emoji

  const handleCardClick = () => {
    setShowEmojis(true);
  };

  const handleEmojiSelect = (emoji) => {
    setSelectedEmoji(emoji);
    setMessage(emoji === '🔥' ? 'This project is lit!' : "Seems like you don't like this project");
    if (emoji === '🔥') {
      setFireCount(fireCount + 1);
    } else {
      setSadCount(sadCount + 1);
    }
    setTimeout(() => {
      setShowEmojis(false);
      setSelectedEmoji(null);
      setMessage('');
    }, 3000); // Hide selected emoji and message after 3 seconds
  };

  const status = isLaunchActive(launch.state.saleStart, launch.state.saleEnd);
  const statusColor = status === 'Ended' ? 'red' : status === 'Ongoing' ? 'green' : 'orange';

  return (
    <>
      <Card
        sx={{ backgroundColor: 'rgba(0, 0, 0, 0)', boxShadow: 2, borderRadius: 2, color: 'white', padding: 2, position: 'relative', cursor: 'pointer', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.05)' } }}
       // onClick={handleCardClick}*/
      >
        <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Avatar 
            src={`https://listing.alphpad.com/listings/${launch.directory}/${launch.logo}`} 
            alt={launch.name} 
            sx={{ marginBottom: 2, width: 60, height: 60 }}
          />
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>{launch.name}</Typography>
            <Typography variant="body2" sx={{ marginBottom: 1 }}>{launch.shortDesc}</Typography>
            <Typography variant="body2" sx={{ color: statusColor }}>
              {status}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, marginTop: 2 }}>
             {/* <Chip label={`🔥 ${fireCount}`} sx={{ fontSize: 20, color:"white", padding: 1 }} />
              <Chip label={`😔 ${sadCount}`} sx={{ fontSize: 20, color:"white", padding: 1 }} />*/}
            </Box>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center' }}>
          <Button size="small" color="primary" href={`https://app.alphpad.com/launchpad/${launch.directory}`} target="_blank">
            More Info
          </Button>
        </CardActions>
      </Card>

      <Backdrop open={showEmojis} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
        {selectedEmoji ? (
          <Fade in={selectedEmoji !== null}>
            <Box sx={{ textAlign: 'center', animation: `${zoomIn} 0.5s ease-out` }}>
              <Typography variant="h1" sx={{ animation: `${bounce} 1s ease-in-out`, fontSize: '4rem' }}>{selectedEmoji}</Typography>
              <Typography variant="h5" sx={{ animation: `${fadeOut} 3s ease-out` }}>{message}</Typography>
            </Box>
          </Fade>
        ) : (
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Chip label="🔥" sx={{ fontSize: 50, padding: 3, animation: `${zoomIn} 0.5s ease-out`, cursor: 'pointer' }} onClick={() => handleEmojiSelect('🔥')} />
            <Chip label="😔" sx={{ fontSize: 50, padding: 3, animation: `${zoomIn} 0.5s ease-out`, cursor: 'pointer' }} onClick={() => handleEmojiSelect('😔')} />
          </Box>
        )}
      </Backdrop>
    </>
  );
};

export default LaunchCard;
