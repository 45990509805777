import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const textPulse = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
`;

const textShadowPulse = keyframes`
  0%, 100% {
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
  }
  50% {
    text-shadow: 4px 4px 16px rgba(0, 0, 0, 0.7);
  }
`;

const backgroundOverlay = keyframes`
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const typing = keyframes`
  from {
    width: 0;
  }
  to {
    width: 30%;
  }
`;

const blink = keyframes`
  0%, 100% {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
`;

const ComingSoon = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        backgroundImage: 'url(/path/to/your/background-image.jpg)', // Replace with your background image path
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: 'white',
        position: 'relative',
        overflow: 'hidden',
        animation: `${fadeIn} 2s ease-in`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          animation: `${backgroundOverlay} 2s ease-in`,
        }}
      />
      <Typography
        variant="h1"
        sx={{
          fontSize: '4rem',
          fontWeight: 'bold',
          textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
          animation: `${textPulse} 2s infinite, ${textShadowPulse} 2s infinite`,
          zIndex: 1,
        }}
      >
        Coming Soon
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontSize: '1.5rem',
          marginTop: 2,
          textShadow: '1px 1px 4px rgba(0, 0, 0, 0.7)',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          borderRight: '0.15em solid white',
          animation: `${typing} 4s steps(40, end) forwards`,
          width: '15ch',
          animationDelay: '1s', // Start typing after "Coming Soon" appears
          zIndex: 1,
        }}
      >
        Stay tuned! Something amazing is on its way.
      </Typography>
    </Box>
  );
};

export default ComingSoon;
