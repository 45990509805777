import { verifySignedMessage } from '@alephium/web3';
import { useConnect, useWallet } from '@alephium/web3-react';
import { useNavigate } from 'react-router-dom';

export async function signMessage(messageToSign, account, signer, setVal, setSignature) {
    try {
        console.log("Signing message");
        const res = await signer?.signMessage({
            signerAddress: account?.address,
            signerKeyType: "default",
            message: messageToSign,
            messageHasher: "alephium"
        });

        if (res) {
            
            const verify = await verifySignedMessage(messageToSign, "alephium", account?.publicKey, res.signature);
            setVal(verify);
            setSignature(res.signature);
            return res;
        }
    } catch (error) {
        if (typeof error === 'string' && error.includes("User rejected")) {
            console.log("User rejected");
            setVal(false);
            return false;
        } else {
            if (error && typeof error === 'object') {

                if (error.message.includes("User rejected"))
                    setVal(false);
                return false;
            } else {
                console.log("Error object is null or undefined");
            }
        }
    }

}
