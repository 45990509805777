import React, { useEffect } from 'react';
import { AlephiumConnectButton } from '@alephium/web3-react';
import { useWallet ,useConnect} from '@alephium/web3-react';
import { Box, Grid,Button } from '@material-ui/core';

const Login = () => {
  const { disconnect }= useConnect()
  const { account} = useWallet();
 

  return (
    <Box item width="40vw" height="5vh">
      <Grid container width="100%" height="100%" direction="row" alignContent='center' justifyContent='center'>
        <AlephiumConnectButton label="Connect" />
        
      </Grid>
    </Box>
  );
}

export default Login;
