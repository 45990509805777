import { gql, useQuery } from "@apollo/client";

export const USER_INFO = gql`
	 query userInfo($address:String) {
		userInfo(address:$address) {
			address
			appPreferences {
			  title
			  headerImg
			  headerImgSrc
			  headerImgColor
			  backgroundImg
			  backgroundImgSrc
			  backgroundImgRepeat
			  backgroundImgColor
			}
		}
	  }
`;
