import axios from "axios";
import { Envs } from "../conf/envs";

const token = localStorage.getItem(Envs.TOKEN_NAME);

axios.defaults.headers.common = {
    Authorization: `Bearer ${token?.substring(1, token.length - 1)}`,
};

const API_URL_AUTH = `https://api-auth.alphpost.com/auth/`;

class AuthService {
    async login(address, publicKey, nonce, signature) {
        try {
            const response = await axios.post(API_URL_AUTH + "login", {
                address,
                nonce,
                publicKey,
                signature
            });

            if (response.data.address !== address) {
                this.logout();
            }

            if (response.data.access_token) {
                localStorage.setItem(
                    Envs.TOKEN_NAME,
                    JSON.stringify(response.data.access_token)
                );
            }

            return { success: true, data: response.data };
        } catch (e) {
            return { success: false, data: { message: e.message } };
        }
    }

    logout() {
        // TODO: Implement logout functionality
    }
}

export default new AuthService();
