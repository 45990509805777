import React, { useState, useEffect, useCallback } from 'react';
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_POSTS } from '../graphql/query/posts';
import { CREATE_POST, LIKE_POST, CREATE_COMMENT } from '../graphql/mutations/posts';
import { useWallet } from '@alephium/web3-react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  styled,
  InputAdornment,
  Grid,
  CircularProgress
} from '@mui/material';
import { IconButton } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import CreateIcon from '@mui/icons-material/Create';
import { Sort } from '@mui/icons-material';
import PostCard from './PostCard';
import { NetworkStatus } from '@apollo/client';
import isEqual from 'lodash/isEqual';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  position: 'relative',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const StyledCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0)',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const PostsWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '600px',
  maxHeight: '50vh',
  overflowY: 'auto',
  paddingRight: theme.spacing(1), // to ensure smooth scrolling
  '&::-webkit-scrollbar': {
    display: 'none', // Hides scrollbar for WebKit browsers
  },
  // For other browsers like Firefox:
  scrollbarWidth: 'none', // This hides the scrollbar in Firefox
}));

const customTextFieldStyles = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255,255,255,0.1)', // Change as per your theme color
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255,255,255,0.3)', // Lighter or darker based on hover state
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(255,255,255,0.2)', // Highlight color when focused
    },
  },
  '& .MuiInputBase-input': {
    color: 'white', // Text color
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)', // Label color
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'white', // Label color when focused
  },
};

const PostComponent = (props) => {
  const { account } = useWallet();
  const { address } = account || {};
  const [order, setOrder] = useState('DESC');
  const [authorFilter, setAuthorFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [offset, setOffset] = useState(0);

  const limit = 10;
 
  const [previousQuery, setPreviousQuery] = useState({});
  const [previousResult, setPreviousResult] = useState(null);

  const [getPosts, { data, loading, error, fetchMore, networkStatus, refetch }] = useLazyQuery(GET_POSTS, {
    variables: { order, author: authorFilter, search: searchTerm, offset, limit },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only', // Ensure fresh data fetch
  });

  const [createPost] = useMutation(CREATE_POST);
  const [likePost] = useMutation(LIKE_POST);
  const [createComment] = useMutation(CREATE_COMMENT);

  const [newPostContent, setNewPostContent] = useState('');
  const [commentContent, setCommentContent] = useState('');
  const [currentPostId, setCurrentPostId] = useState('');
  const [expandedPosts, setExpandedPosts] = useState({});

  useEffect(() => {
    getPosts();
  }, [getPosts]);

  const handleCreatePost = async () => {
    try {
      await createPost({
        variables: {
          createPostInput: {
            content: newPostContent,
            author: address,
          },
        },
      });
      setNewPostContent('');
      refetch(); // Refetch posts after creating a new post
    } catch (err) {
      console.error("Error creating post:", err);
    }
  };

  const toggleOrder = () => {
    const newOrder = order === 'DESC' ? 'ASC' : 'DESC';
    setOrder(newOrder);
    setOffset(0);
    const newQuery = { order: newOrder, author: authorFilter, search: searchTerm, offset: 0, limit };
    if (!isEqual(previousQuery, newQuery)) {
      setPreviousQuery(newQuery);
      getPosts({ variables: newQuery });
    }
  };

  // Add a useEffect to update the query when searchTerm changes
  useEffect(() => {
    const newQuery = { order, author: authorFilter, search: searchTerm, offset, limit };
    if (!isEqual(previousQuery, newQuery)) {
      setPreviousQuery(newQuery);
      getPosts({ variables: newQuery });
    }
  }, [searchTerm, order, authorFilter, offset]);

  const handleLikePost = (postId) => {
    likePost({
      variables: {
        likePostInput: {
          postId,
          user: address,
        },
      },
      refetchQueries: [{ query: GET_POSTS }],
    });
  };

  const handleCreateComment = (postId) => {
    createComment({
      variables: {
        createCommentInput: {
          postId,
          content: commentContent,
          user: address,
        },
      },
      refetchQueries: [{ query: GET_POSTS, variables: { id: postId } }],
    });
    setCommentContent('');
    setCurrentPostId('');
    refetch(); // Refetch posts after creating a comment
  };

  const handleOrderChange = (event) => {
    const newOrder = event.target.value;
    setOrder(newOrder);
    setOffset(0);
    const newQuery = { order: newOrder, author: authorFilter, search: searchTerm, offset: 0, limit };
    if (!isEqual(previousQuery, newQuery)) {
      setPreviousQuery(newQuery);
      getPosts({ variables: newQuery });
    }
  };

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        offset: data?.posts.length,
        limit,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          posts: [...prev?.posts, ...fetchMoreResult?.posts],
        };
      },
    });
  };

  const handleScroll = useCallback((e) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      handleLoadMore();
    }
  }, [handleLoadMore]);

  if (error) return <Typography>Error: {error.message}</Typography>;

  return (
    <StyledContainer maxWidth="md">
      <TextField
        label="Search Posts"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <SearchIcon style={{ color: 'white' }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ marginBottom: 2, ...customTextFieldStyles }}
      />

      <StyledCard>
        <TextField
          label="Write a new post"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={newPostContent}
          onChange={(e) => setNewPostContent(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CreateIcon style={{ color: 'white' }} />
              </InputAdornment>
            ),
          }}
          sx={customTextFieldStyles}
        />
        <Button
          color="primary"
          onClick={handleCreatePost}
          sx={{ marginTop: 1, width: '100%' }}
        >
          Create Post
        </Button>
      </StyledCard>
      <Grid container width="100%" direction="row" alignItems="center" justifyContent="flex-start">
        <Grid item></Grid>
        <Sort onClick={toggleOrder}></Sort>
      </Grid>
      <PostsWrapper onScroll={handleScroll}>
        {loading && networkStatus !== NetworkStatus.fetchMore && (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        )}
        {!loading && data?.posts?.length === 0 && (
          <Typography>Nothing yet 😊</Typography>
        )}
        {data?.posts?.length > 0 && (
          data.posts.map((post) => (
            <PostCard
              key={post.id}
              post={post}
              currentPostId={currentPostId}
              expandedPosts={expandedPosts}
              setCurrentPostId={setCurrentPostId}
              setExpandedPosts={setExpandedPosts}
              handleLikePost={handleLikePost}
              handleCreateComment={handleCreateComment}
              commentContent={commentContent}
              setCommentContent={setCommentContent}
            />
          ))
        )}
      </PostsWrapper>
    </StyledContainer>
  );
};

export default PostComponent;
