import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Box, Typography, IconButton, Button, styled, AppBar, Toolbar, CircularProgress } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PostIcon from '@mui/icons-material/Article';
import Posts from './PostPerfil';
import { USER_INFO } from '../graphql/query/getUser';
import { CREATE_FOLLOW } from '../graphql/mutations/createFollower';
import { IS_FOLLOWING } from '../graphql/query/isFollowing';
import { FOLLOWERS_COUNT } from '../graphql/query/getFollowers';

const StyledContainer = styled(Box)(({ theme, backgroundImg, backgroundImgSrc, backgroundImgRepeat, backgroundImgColor }) => ({
    position: 'relative',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    backgroundSize: 'cover',
    backgroundRepeat: backgroundImgRepeat ? 'repeat' : 'no-repeat',
    backgroundColor: backgroundImgColor || 'transparent',
    ...(backgroundImg && {
        backgroundImage: `url(${backgroundImgSrc})`,
    }),
    overflow: 'hidden',
}));

const Header = styled(Box)(({ headerImg, headerImgSrc, headerImgColor }) => ({
    width: '100%',
    minHeight: '20vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundColor: headerImgColor || 'transparent',
    ...(headerImg && {
        backgroundImage: `url(${headerImgSrc})`,
    }),
    position: 'relative',
}));

const SecondaryNavbar = styled(AppBar)(({ theme }) => ({
    top: 'auto',
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
}));

const LoadingOverlay = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
});

const ErrorMessage = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    color: 'red',
});

const ForeingPerfil = ({ address }) => {
    const { loading, error, data, refetch } = useQuery(USER_INFO, { variables: { address } });
    const { loading: isFollowingLoading, error: isFollowingError, data: isFollowingData, refetch: refetchIsFollowing } = useQuery(IS_FOLLOWING, { variables: { address } });
    const { loading: followersLoading, error: followersError, data: followersData, refetch: refetchFollowers } = useQuery(FOLLOWERS_COUNT, { variables: { address } });
    const [createFollower] = useMutation(CREATE_FOLLOW);
    const [activeView, setActiveView] = useState('posts');

    useEffect(() => {
        if (address) {
            refetch();
            refetchIsFollowing();
            refetchFollowers();
        }
    }, [address, refetch, refetchIsFollowing, refetchFollowers]);

    const handleFollow = () => {
        const actionType = isFollowingData.isFollowing ? 'Unfollow' : 'Follow';

        createFollower({
            variables: {
                createFollowerInput: {
                    actionToId: address,
                    action: actionType,
                },
            },
        })
            .then(response => {
                console.log(`${actionType} successful:`, response.data.createFollower);
                refetchIsFollowing();
                refetchFollowers();
            })
            .catch(err => {
                console.error(`Error with ${actionType}:`, err);
            });
    };

    if (loading || isFollowingLoading || followersLoading) {
        return (
            <LoadingOverlay>
                <CircularProgress />
            </LoadingOverlay>
        );
    }

    if (error || isFollowingError || followersError) {
        return (
            <ErrorMessage>
                Error! {error?.message || isFollowingError?.message || followersError?.message}
            </ErrorMessage>
        );
    }

    const { title, headerImg, headerImgSrc, headerImgColor, backgroundImg, backgroundImgSrc, backgroundImgRepeat, backgroundImgColor } = data?.userInfo.appPreferences || {};

    const renderContent = () => {
        switch (activeView) {
          
            case 'portfolio':
                return <div>Portfolio</div>;
            case 'posts':
            default:
                return <Posts address={address} />;
        }
    };

    return (
        <StyledContainer
            backgroundImg={backgroundImg}
            backgroundImgSrc={backgroundImgSrc}
            backgroundImgRepeat={backgroundImgRepeat}
            backgroundImgColor={backgroundImgColor}
        >
            <Header headerImg={headerImg} headerImgSrc={headerImgSrc} headerImgColor={headerImgColor}>
                <Typography variant="h3" color="white">
                    {title || 'Profile'}
                </Typography>
                <Typography variant="h6" color="white">
                    {followersData?.followersByUser} Followers
                </Typography>
            </Header>

            <SecondaryNavbar position="static">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => setActiveView('posts')}>
                        <PostIcon />
                    </IconButton>
                 
                    <IconButton color="inherit" onClick={() => setActiveView('portfolio')}>
                        <WorkOutlineIcon />
                    </IconButton>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFollow}
                        sx={{ position: 'absolute', right: 20, top: 20 }}
                    >
                        {isFollowingData.isFollowing ? 'Unfollow' : 'Follow'}
                    </Button>
                </Toolbar>
            </SecondaryNavbar>

            {renderContent()}
        </StyledContainer>
    );
};

export default ForeingPerfil;
