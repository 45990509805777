import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Me } from '../graphql/query/ME';
import { useWallet } from '@alephium/web3-react';
import {
    Box, Typography, IconButton, styled, AppBar, Toolbar
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import PostIcon from '@mui/icons-material/Article';
import Posts from './PostPerfil'; // Import the Posts component
import Settings from './Settings'; // Import the Settings component

const StyledContainer = styled(Box)(({ theme, backgroundImg, backgroundImgSrc, backgroundImgRepeat, backgroundImgColor }) => ({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: 'relative',
    height: '100vh',
    width: "100vw",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    ...(backgroundImg ? {
        backgroundImage: `url(${backgroundImgSrc})`,
    } : {
        backgroundColor: backgroundImgColor || 'transparent',
    }),
    backgroundSize: 'cover',
    backgroundRepeat: backgroundImgRepeat ? 'repeat' : 'no-repeat',
}));

const Header = styled(Box)(({ headerImg, headerImgSrc, headerImgColor }) => ({
    marginTop: 0,
    width: '100%',
    minHeight: '20vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ...(headerImg ? {
        backgroundImage: `url(${headerImgSrc})`,
    } : {
        backgroundColor: headerImgColor || 'transparent',
    }),
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
    },
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

const SecondaryNavbar = styled(AppBar)(({ theme }) => ({
    top: 'auto',
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0)',
}));

const Perfil = () => {
    const { account } = useWallet();
    const { address } = account || {};
    const { loading: loadingMe, error: errorMe, data: dataMe, refetch: refetchMe } = useQuery(Me);

    const [activeView, setActiveView] = useState('posts');

    useEffect(() => {
        if (address) {
            refetchMe();
        }
    }, [address]);



   const fnRefetch=()=>{
    refetchMe()
   }
    if (loadingMe) return <div>Loading...</div>;
    if (errorMe) return <div>Error! {errorMe.message}</div>;

    const { appPreferences } = dataMe.me || {};
    const {
        title,
        headerImg,
        headerImgSrc,
        headerImgColor,
        backgroundImg,
        backgroundImgSrc,
        backgroundImgRepeat,
        backgroundImgColor
    } = appPreferences || {};

    const renderContent = () => {
        switch (activeView) {
            case 'posts':
                return <Posts address={address} />;
            case 'settings':
                return <Settings appPreferences={appPreferences} id={dataMe.me._id}  fnRefetch={fnRefetch}/>;
           
            case 'portfolio':
                return <div>Portfolio</div>;
            default:
                return <div>Posts</div>;
        }
    };

    return (
        <StyledContainer
            backgroundImg={backgroundImg}
            backgroundImgSrc={backgroundImgSrc}
            backgroundImgRepeat={backgroundImgRepeat}
            backgroundImgColor={backgroundImgColor}
        >
            <Header headerImg={headerImg} headerImgSrc={headerImgSrc} headerImgColor={headerImgColor}>
                <Typography variant="h3" color="white">
                    {title || 'Profile'}
                </Typography>
            </Header>

            <SecondaryNavbar position="static">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => setActiveView('posts')}>
                        <PostIcon />
                    </IconButton>
                    <IconButton color="inherit" onClick={() => setActiveView('settings')}>
                        <SettingsIcon />
                    </IconButton>
                   
                    <IconButton color="inherit" onClick={() => setActiveView('portfolio')}>
                        <WorkOutlineIcon />
                    </IconButton>
                </Toolbar>
            </SecondaryNavbar>

            {renderContent()}
        </StyledContainer>
    );
};

export default Perfil;
