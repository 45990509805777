import { gql } from '@apollo/client';

export const CREATE_FOLLOW = gql`
  mutation CreateFollow($createFollowerInput: CreateFollowerInput!) {
    createFollower(createFollowerInput: $createFollowerInput) {
      id
      actionToId
      action
    }
  }
`;