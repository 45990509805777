"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unlockScriptCodec = exports.UnlockScriptCodec = exports.P2SHCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const binary_parser_1 = require("binary-parser");
const array_codec_1 = require("./array-codec");
const compact_int_codec_1 = require("./compact-int-codec");
const codec_1 = require("./codec");
const script_codec_1 = require("./script-codec");
const bytestring_codec_1 = require("./bytestring-codec");
const lockup_script_codec_1 = require("./lockup-script-codec");
const utils_1 = require("../utils");
class P2PKHCodec {
    constructor() {
        this.parser = (0, codec_1.fixedSizeBytes)('publicKey', 33);
    }
    encode(input) {
        return input.publicKey;
    }
    decode(input) {
        return this.parser.parse(input);
    }
}
const p2pkhCodec = new P2PKHCodec();
class P2MPKHCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start().nest('publicKeys', {
            type: array_codec_1.ArrayCodec.arrayParser(binary_parser_1.Parser.start()
                .nest('publicKey', { type: p2pkhCodec.parser })
                .nest('index', { type: compact_int_codec_1.compactUnsignedIntCodec.parser }))
        });
    }
    encode(input) {
        return (0, utils_1.concatBytes)([
            compact_int_codec_1.compactUnsignedIntCodec.encode(input.publicKeys.length),
            ...input.publicKeys.value.map((v) => {
                return (0, utils_1.concatBytes)([v.publicKey.publicKey, compact_int_codec_1.compactUnsignedIntCodec.encode(v.index)]);
            })
        ]);
    }
    decode(input) {
        return this.parser.parse(input);
    }
}
const p2mpkhCodec = new P2MPKHCodec();
class ValCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start()
            .int8('type')
            .choice('val', {
            tag: 'type',
            choices: {
                0x00: new binary_parser_1.Parser().uint8('value'),
                0x01: compact_int_codec_1.compactSignedIntCodec.parser,
                0x02: compact_int_codec_1.compactUnsignedIntCodec.parser,
                0x03: bytestring_codec_1.byteStringCodec.parser,
                0x04: lockup_script_codec_1.lockupScriptCodec.parser // Address
            }
        });
    }
    encode(input) {
        const valType = input.type;
        if (valType === 0x00) {
            // Boolean
            return new Uint8Array([valType, input.val]);
        }
        else if (valType === 0x01) {
            // I256
            return new Uint8Array([valType, ...compact_int_codec_1.compactUnsignedIntCodec.encode(input.val)]);
        }
        else if (valType === 0x02) {
            // U256
            return new Uint8Array([valType, ...compact_int_codec_1.compactUnsignedIntCodec.encode(input.val)]);
        }
        else if (valType === 0x03) {
            // ByteVec
            return new Uint8Array([valType, ...bytestring_codec_1.byteStringCodec.encode(input.val)]);
        }
        else if (valType === 0x04) {
            // Address
            return new Uint8Array([valType, ...lockup_script_codec_1.lockupScriptCodec.encode(input.val)]);
        }
        else {
            throw new Error(`ValCodec: unsupported val type: ${valType}`);
        }
    }
    decode(input) {
        return this.parser.parse(input);
    }
}
const valCodec = new ValCodec();
const valsCodec = new array_codec_1.ArrayCodec(valCodec);
class P2SHCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start()
            .nest('script', {
            type: script_codec_1.scriptCodec.parser
        })
            .nest('params', {
            type: valsCodec.parser
        });
    }
    encode(input) {
        return (0, utils_1.concatBytes)([script_codec_1.scriptCodec.encode(input.script), valsCodec.encode(input.params.value)]);
    }
    decode(input) {
        return this.parser.parse(input);
    }
}
exports.P2SHCodec = P2SHCodec;
const p2shCodec = new P2SHCodec();
class UnlockScriptCodec {
    constructor() {
        this.parser = binary_parser_1.Parser.start()
            .uint8('scriptType')
            .choice('script', {
            tag: 'scriptType',
            choices: {
                0: p2pkhCodec.parser,
                1: p2mpkhCodec.parser,
                2: p2shCodec.parser,
                3: binary_parser_1.Parser.start() // TODO: SameAsPrevious, FIXME
            }
        });
    }
    encode(input) {
        const scriptType = input.scriptType;
        const inputUnLockScript = input.script;
        if (scriptType === 0) {
            // P2PKH
            return new Uint8Array([scriptType, ...p2pkhCodec.encode(inputUnLockScript)]);
        }
        else if (scriptType === 1) {
            // P2MPKH
            return new Uint8Array([scriptType, ...p2mpkhCodec.encode(inputUnLockScript)]);
        }
        else if (scriptType === 2) {
            // P2SH
            return new Uint8Array([scriptType, ...p2shCodec.encode(inputUnLockScript)]);
        }
        else if (scriptType === 3) {
            // SameAsPrevious
            return new Uint8Array([scriptType]);
        }
        else {
            throw new Error(`TODO: encode unlock script: ${scriptType}`);
        }
    }
    decode(input) {
        return this.parser.parse(input);
    }
}
exports.UnlockScriptCodec = UnlockScriptCodec;
exports.unlockScriptCodec = new UnlockScriptCodec();
