import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { styled, keyframes } from '@mui/system';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useWallet } from '@alephium/web3-react';
// Define the keyframes for the pulsating effect
const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
`;

// Styled component for the pulsating ball
const PulsatingBall = styled('div')`
  width: 15px;
  height: 15px;
  background-color: #007bff;
  border-radius: 50%;
  animation: ${pulse} 2s infinite;
`;

const Navbar = (props) => {
  const highlightAddress = (address) => {
    if (!address) return '';
    const start = `${address.slice(0, 5)}`;
    const end = `${address.slice(-4)}`;
    return (
        <span>
            <a style={{ color: "#007bff" }}>{start}</a>
            <a style={{ color: "white" }}>...{end}</a>
        </span>
    );
};
  const { account, signer } = useWallet();
  const navigate=useNavigate();
  return (
    <AppBar sx={{ backgroundColor: "rgba(0,0,0,0.1)" }} position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
        <Button onClick={()=>{
            props.setSet("perfil")
          }}>
        <Typography variant="h6" component="div" >
          {highlightAddress(account?.address)  || "ALPHPOST"} 
        </Typography>
        </Button>
        </Box>
        <Box>
          <Tooltip title="Social" onClick={()=>{
           props.setSet("social")
          }}>
            <IconButton color="inherit">
              <ConnectWithoutContactIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Pulse" onClick={()=>{
             props.setSet("pulse")
          }}>
            <IconButton color="inherit">
              <PulsatingBall />
            </IconButton>
          </Tooltip>
          <Tooltip title="Alphapad" onClick={()=>{
            props.setSet("alphpad")
          }}>
            <IconButton color="inherit">
              <RocketLaunchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Marketplace" onClick={()=>{
            props.setSet("marketplace")
          }}>
            <IconButton color="inherit">
              <StorefrontIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
