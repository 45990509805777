import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import LoginPage from './LoginPage';
import Dashboard from './Dashboard'; // Assuming you have a Dashboard component
import { AuthenticatedRoute, UnauthenticatedRoute } from './ProtectedRoutes';
import Navbar from './components/Navbar';
const App = () => {
  return (
    <>
      
    <Routes>
     
      <Route path="/" element={<Navigate to="/login" />} />
      <Route element={<UnauthenticatedRoute />}>
        <Route path="/login" element={<LoginPage />} />
      </Route>
      <Route element={<AuthenticatedRoute />}>
        <Route path="/social" element={<Dashboard />} />
       
      </Route>
    </Routes>
    </>
  );
};

export default App;
