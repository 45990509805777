import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { toast } from 'react-hot-toast';
import {
    Box, Typography, TextField, Button, FormControlLabel, Checkbox, styled, Paper, IconButton, Collapse
} from '@mui/material';
import { Envs } from '../conf/envs';
import { SketchPicker } from 'react-color';
import ColorLensIcon from '@mui/icons-material/ColorLens';

const SET_PREFERENCES = gql`
    mutation SetPreferences($input: AppPreferencesInput!) {
        setPreferences(input: $input)
    }
`;

const SettingsContainer = styled(Paper)(({ theme }) => ({
    width: '100vw',
    color: "white",
    height: '70vh', // Reduced height
    overflow: 'auto',
    padding: theme.spacing(4),
    backgroundColor: 'rgba(255, 255, 255, 0)',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[10],
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    },
}));

const FormTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(3),
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.primary.main,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.7)',
        },
        '&:hover fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.9)',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
    },
    '& .MuiInputBase-input': {
        color: 'white',
    },
    '& .MuiInputLabel-root': {
        color: 'rgba(255, 255, 255, 0.7)',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: 'white',
    },
}));

const ColorPickerButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
}));

const Settings = ({ appPreferences, id, fnRefetch }) => {
    const [preferences, setPreferences] = useState({
        id: id,
        title: '',
        headerImg: false,
        headerImgSrc: '',
        headerImgColor: 'rgba(255, 255, 255, 1)',
        backgroundImg: false,
        backgroundImgSrc: '',
        backgroundImgRepeat: false,
        backgroundImgColor: 'rgba(255, 255, 255, 1)',
        token: localStorage.getItem(Envs.TOKEN_NAME).replace(/"/g, '')
    });

    const [setPreferencesMutation] = useMutation(SET_PREFERENCES);
    const [showHeaderColorPicker, setShowHeaderColorPicker] = useState(false);
    const [showBackgroundColorPicker, setShowBackgroundColorPicker] = useState(false);

    useEffect(() => {
        if (appPreferences) {
            setPreferences((prevPreferences) => ({
                ...prevPreferences,
                ...appPreferences
            }));
        }
    }, [appPreferences]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleColorChange = (color, name) => {
        setPreferences((prevPreferences) => ({
            ...prevPreferences,
            [name]: `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { __typename, ...cleanPreferences } = preferences;
        try {
            await setPreferencesMutation({ variables: { input: cleanPreferences } });
            toast.success('Preferences updated successfully!');
            fnRefetch();
        } catch (error) {
            toast.error('Error updating preferences. Please try again.');
            console.error('Error updating preferences:', error);
        }
    };

    return (
        <SettingsContainer>
            <FormTitle variant="h4">
                Settings
            </FormTitle>
            <form onSubmit={handleSubmit}>
                <StyledTextField
                    label="Title"
                    name="title"
                    fullWidth
                    margin="normal"
                    value={preferences.title}
                    onChange={handleChange}
                    variant="outlined"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={preferences.headerImg}
                            onChange={(e) => {
                                handleChange(e);
                                if (e.target.checked) setShowHeaderColorPicker(false);
                            }}
                            name="headerImg"
                        />
                    }
                    label="Header Image"
                />
                {preferences.headerImg ? (
                    <StyledTextField
                        label="Header Image Source"
                        name="headerImgSrc"
                        fullWidth
                        margin="normal"
                        value={preferences.headerImgSrc}
                        onChange={handleChange}
                        variant="outlined"
                    />
                ) : (
                    <>
                        <Box display="flex" alignItems="center" mt={2}>
                            <Typography variant="body1">Header Image Color</Typography>
                            <ColorPickerButton onClick={() => setShowHeaderColorPicker(!showHeaderColorPicker)}>
                                <ColorLensIcon />
                            </ColorPickerButton>
                        </Box>
                        <Collapse in={showHeaderColorPicker}>
                            <SketchPicker
                                color={preferences.headerImgColor}
                                onChangeComplete={(color) => handleColorChange(color, 'headerImgColor')}
                            />
                        </Collapse>
                    </>
                )}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={preferences.backgroundImg}
                            onChange={(e) => {
                                handleChange(e);
                                if (e.target.checked) setShowBackgroundColorPicker(false);
                            }}
                            name="backgroundImg"
                        />
                    }
                    label="Background Image"
                />
                {preferences.backgroundImg ? (
                    <StyledTextField
                        label="Background Image Source"
                        name="backgroundImgSrc"
                        fullWidth
                        margin="normal"
                        value={preferences.backgroundImgSrc}
                        onChange={handleChange}
                        variant="outlined"
                    />
                ) : (
                    <>
                        <Box display="flex" alignItems="center" mt={2}>
                            <Typography variant="body1">Background Image Color</Typography>
                            <ColorPickerButton onClick={() => setShowBackgroundColorPicker(!showBackgroundColorPicker)}>
                                <ColorLensIcon />
                            </ColorPickerButton>
                        </Box>
                        <Collapse in={showBackgroundColorPicker}>
                            <SketchPicker
                                color={preferences.backgroundImgColor}
                                onChangeComplete={(color) => handleColorChange(color, 'backgroundImgColor')}
                            />
                        </Collapse>
                    </>
                )}
                {preferences.backgroundImg && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={preferences.backgroundImgRepeat}
                                onChange={handleChange}
                                name="backgroundImgRepeat"
                            />
                        }
                        label="Background Image Repeat"
                    />
                )}
                <Box mt={4} textAlign="center">
                    <Button type="submit" variant="contained" color="primary">
                        Save Preferences
                    </Button>
                </Box>
            </form>
        </SettingsContainer>
    );
};

export default Settings;
